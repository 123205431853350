<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12">
        <keep-alive>
          <router-view />
        </keep-alive>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AddEditView",
  components: {},
  data(){
    return{
    }
  },
  computed: {
  },
  watch:{
  },
  mounted(){
  },
  methods:{
  }
};
</script>
